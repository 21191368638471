import { GetJobsDto } from "@reactive-resume/dto";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface JobDataState {
  jobData: GetJobsDto[] | [];
}

interface JobDataActions {
  setJobData: (jobData: GetJobsDto[] | []) => void;
}

export const useJobDataStore = create<JobDataState & JobDataActions>()(
  persist(
    (set) => ({
      jobData: [],
      setJobData: (jobData) => set({ jobData }),
    }),
    { name: "jobData" },
  ),
);
