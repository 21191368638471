/* eslint-disable lingui/text-restrictions */

import axios from "axios";

const PROMPT = `Your job is to generate a well-written, professional cover letter for the following job in cover letter format: {job_title}, at the following company, {company} that does not sound like AI, is not too verbose and will maximize the chances of receiving that job. Ensure that the cover letter effectively addresses the following job description: {job_description}. Ensure that you make the letter specific to the job and the user, and take and rewrite seamlessly into the cover letter any relevant information that will improve the quality of the cover letter from the following user information:\n\n{user_profile_info} Do not return anything else except the cover letter. It should not begin with a newline or have a letterhead. It should not have any prefix or suffix text. You should not lie or make up any information about the user's job, employment or academic history that was not explicitly stated or strongly implied in their user information.`;

export const coverLetter = async (job_title: string, company: string, job_description: string, user_profile_info: string) => {
  const prompt = PROMPT.replace("{job_title}", job_title).replace("{company}", company).replace("{job_description}", job_description).replace("{user_profile_info}", user_profile_info);

  const response = await axios.post(
    "/api/openai/send",
    {prompt},
  );

  return response.data ?? job_title;
};
