import { ResumeDto } from "@reactive-resume/dto";
import { useQuery, QueryObserverOptions } from "@tanstack/react-query";

import { RESUME_KEY } from "@/client/constants/query-keys";
import { axios } from "@/client/libs/axios";
import { useResumeStore } from "@/client/stores/resume";
import { useEffect } from "react";

export const findResumeById = async (data: { id: string }) => {
  const response = await axios.get<ResumeDto>(`/resume/${data.id}`);

  return response.data;
};

export const findResumeByUsernameSlug = async (data: { username: string; title: string }) => {
  // console.log("here baby!!!");
  const response = await axios.get<ResumeDto>(`/resume/all/${data.username}/${data.title}`);

  return response.data;
};

export const useResume = (id: string) => {
  const {
    error,
    isPending: loading,
    data: resume,
  } = useQuery({
    queryKey: [RESUME_KEY, { id }],
    queryFn: () => findResumeById({ id }),
  });

  useResumeStore.setState({ resume });
  useResumeStore.temporal.getState().clear();

  return { resume, loading, error };
};

export const useDummyResume = (username: string, title: string) => {

  const { error, isLoading: loading, data } = useQuery<ResumeDto, Error>({
    queryKey: ["resume", { username, title }],
    queryFn: () => findResumeByUsernameSlug({ username, title }),
    staleTime: 0, // Set staleTime in milliseconds
  });

  return { resume: data, loading, error };
};


// export const useGetUserProfile = (username: string, title: string) => {
//   const setResumeData = useResumeDataStore((state) => state.setResumeData);

//   const { error, isLoading: loading, data } = useQuery<ResumeDto[], Error>({
//     queryKey: ["resumeData"],
//     queryFn: () => findResumeByUsernameSlug({username, title}),
//   });

//   useEffect(() => {
//     if (data) {
//       setResumeData(data);
//     }
//   }, [data, setResumeData]);

//   return { resumeData: data, loading, error };
// };

// export const useUpdateResume = () => {
//   const {
//     error,
//     isPending: loading,
//     mutateAsync: updateResumeFn,
//   } = useMutation({
//     mutationFn: updateResume,
//   });

//   return { updateResume: updateResumeFn, loading, error };
// };

