import { createZodDto } from 'nestjs-zod/dto';
import { z } from 'nestjs-zod/z';

export const jobsSchema = z.object({
  id: z.number(),
  unique_identifier: z.string(),
  job_title: z.string(),
  company_name: z.string(),
  url: z.string().url(),
  end_date: z.string().nullable().default("N/A"),
  web_site: z.string().url(),
  category: z.string(),
  scrape_date: z.date(),
  is_latest: z.boolean(),
  is_active: z.boolean(),
  email: z.string().nullable().default("N/A"),
  job_desc: z.string().nullable().default("N/A"),
});

export class JobsDto extends createZodDto(jobsSchema) {}
