import DataTables, { Config } from "datatables.net-dt";
import { useEffect, useRef } from "react";

export function ReactDataTables({ ...props }: Config & { pageLength?: number }) {
  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    const dt = new DataTables(tableRef.current!, { ...props, pageLength: props.pageLength || 25 });
    return () => {
      dt.destroy();
    };
  }, [props.pageLength]);

  return <table ref={tableRef}></table>;
}

export default ReactDataTables;
