import { t } from "@lingui/macro";
import { FacebookLogo, GithubLogo, GoogleLogo } from "@phosphor-icons/react";
import { Button } from "@reactive-resume/ui";

import { useAuthProviders } from "@/client/services/auth/providers";

export const SocialAuth = () => {
  const { providers } = useAuthProviders();

  if (!providers || providers.length === 0) return null;

  return (
     <div className="grid grid-cols-3 gap-2">
       {providers.includes("facebook") && (
        <Button
          asChild
          size="lg"
          className="w-full !bg-[#1877F2] !text-white hover:!bg-[#3b5998]/80"
        >
          <a href="/api/auth/facebook">
            {`Facebook`}
          </a>

        </Button>
      )}

      {providers.includes("github") && (
        <Button asChild size="lg" className="w-full !bg-[#222] !text-white hover:!bg-[#222]/80">
          <a href="/api/auth/github">
            {/* <GithubLogo className="mr-3 size-5" /> */}
            {t`GitHub`}
          </a>
        </Button>
      )}

      {providers.includes("google") && (
        <Button
          asChild
          size="lg"
          className="w-full !bg-[#4285F4] !text-white hover:!bg-[#3f7dc1]/80"
        >
          <a href="/api/auth/google">
            {/* <GoogleLogo className="mr-3 size-5" /> */}
            {t`Google`}
          </a>
        </Button>
      )}
    </div>
  );
};

// Google - 4285F4
