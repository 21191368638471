import { useUser } from "@/client/services/user";
import { ResumeDto } from "@reactive-resume/dto";
import { useEffect, useState } from "react";
import { WarningCircle } from "@phosphor-icons/react";
import { LeftSidebar } from "@/client/pages/builder/sidebars/left";
import { findResumeByUsernameSlug } from "@/client/services/resume";
import { useResumeStore } from "@/client/stores/resume";


export const BasicsUserProfile = () => {
  const { user } = useUser();
  const [resume, setResume] = useState<ResumeDto | null>(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetchResume = async () => {
      try {
        const username = user ? user.username : '';
        const title = 'ignorethisitem-ignore';
        const fetchedResume = await findResumeByUsernameSlug({ username, title });
        console.log(fetchedResume,"check fetch resume");
        useResumeStore.setState({ resume: fetchedResume });
        useResumeStore.setState(prevState => ({ ...prevState, fetchedResume }));
        useResumeStore.temporal.getState().clear();
        setResume(fetchedResume);
        setLoader(false); // Set loading state to false after fetching data
      } catch (error) {
        console.error('Error fetching resume:', error);
        setLoader(false); // Set loading state to false in case of an error
      }
    };

    fetchResume();
  }, []);

  if (!user) return null;

  return (
      <>
      {loader ? "Loading..." :
      <div className="space-y-6">
        <>
        {!user.isVerified ?
          <div
          style={{
            padding: '20px',
            backgroundColor: '#f44336',
            color: 'white',
            borderRadius: '5px',
          }}
        >
          <div style={{display: 'flex'}}>
            <WarningCircle size={20} />
            <span style={{marginLeft: '10px'}}><strong>Alert!</strong> Please fill out your profile and wait to be verified to use the system. To access your free trial, please contact +592 650 6623 on whatsapp or email careerforgeguyana@gmail.com.</span>
          </div>
        </div> :
        <></>
        }

        {user.data.basics.email.includes('facebook.com') ?

            <div
            style={{
              padding: '20px',
              backgroundColor: '#f44336',
              color: 'white',
              borderRadius: '5px',
            }}
          >
            <div style={{display: 'flex'}}>
              <WarningCircle size={20} />
              <span style={{marginLeft: '10px'}}><strong>Alert!</strong> Please update your email to receive job alerts.</span>
            </div>
          </div> :
        <></>}</>
        <div className="w-full">
         {resume && <LeftSidebar />}
        </div>
      </div>}
      </>
    );
}

// const loadBuilderData = async () => {
  //   try {
  //     const username = user ? user.username : '';
  //     const title = "ignorethisitem-ignore";
  //     const resumee = await queryClient.fetchQuery({
  //       queryKey: ["resume", { username, title }],
  //       queryFn: () => findResumeByUsernameSlug({ username, title }),
  //     });

  //     useResumeStore.setState({ resume: resumeData });
  //     useResumeStore.setState(prevState => ({ ...prevState, resumeData }));
  //     useResumeStore.temporal.getState().clear();
  //     setResume(resumeData);
  //   } catch (error) {
  //     console.error('Error loading builder data:', error);
  //   }
  // };


// return (
//   <>
//   {loading ? "Loading..." :
//   <div className="space-y-6">
//     <>{user.data.basics.email.includes('facebook.com') ?

//         <div
//         style={{
//           padding: '20px',
//           backgroundColor: '#f44336',
//           color: 'white',
//           borderRadius: '5px',
//         }}
//       >
//         <div style={{display: 'flex'}}>
//           <WarningCircle size={20} />
//           <span style={{marginLeft: '10px'}}><strong>Alert!</strong> Please update your email to receive job alerts.</span>
//         </div>
//       </div> :
//     <></>}</>
//     <div className="w-full">
//      {resume && <LeftSidebar />}
//     </div>
//   </div>}
//   </>
// );


  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await findResumeByUsernameSlug({ username: user ? user.username : '', title: 'ignorethisitem-ignore' });
  //       console.log(response, "check response");
  //       setResume(response); // Assuming you want to set the resume state with the response
  //       setLoader(false);
  //     } catch (error) {
  //       console.error('Error fetching resume:', error);
  //       setLoader(false); // Assuming you also want to set the loader to false in case of an error
  //     }
  //   };

  //   fetchData();
  // }, []);

  // const { resume: resumeData, loading } = useDummyResume(user ? user.username : '', 'ignorethisitem-ignore');
  // // const [loader, setLoader] = useState(true);

  // const [resume, setResume] = useState<ResumeDto | null>(null);

  // useEffect(() => {
  //   resumeData && user;
  //   if (resumeData) {
  //     useResumeStore.setState({ resume: resumeData });
  //     useResumeStore.setState(prevState => ({ ...prevState, resumeData }));
  //     useResumeStore.temporal.getState().clear();
  //     setResume(resumeData);

  //   }
  //   console.log(resumeData,"resume check")

  // }, [resumeData]);

  // const loadBuilderData = async () => {
  //   try {
  //     const username = user ? user.username : '';
  //     const title = "ignorethisitem-ignore";
  //     const resumee = await queryClient.fetchQuery({
  //       queryKey: ["resume", { username, title }],
  //       queryFn: () => findResumeByUsernameSlug({ username, title }),
  //     });

  //     useResumeStore.setState({ resume: resumeData });
  //     useResumeStore.setState(prevState => ({ ...prevState, resumee }));
  //     useResumeStore.temporal.getState().clear();
  //     setResume(resumee);
  //   } catch (error) {
  //     console.error('Error loading builder data:', error);
  //   }
  // };
