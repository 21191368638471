// import { JobsDto } from "@reactive-resume/dto";
// import { useQuery } from "@tanstack/react-query";
// import { AxiosResponse } from "axios";
// import { useEffect } from "react";

// import { axios } from "@/client/libs/axios";
// import { useAuthStore } from "@/client/stores/auth";

// export const fetchCategories = async () => {
//   const response = await axios.get<JobsDto, AxiosResponse<JobsDto>>("/jobs/categories");

//   return response.data;
// };

// export const useJobCategory = () => {
//   const {
//     error,
//     isLoading: loading,
//     data: jobCategories,
//   } = useQuery({queryKey: ["jobCategories"], queryFn: fetchCategories});

//   return { jobCategories, loading, error };
// };

import { JobsDto } from "@reactive-resume/dto";
import { useQuery } from "@tanstack/react-query";
import { axios } from "@/client/libs/axios";
import { useAuthStore } from "@/client/stores/auth";

export const fetchCategories = async () => {
  const response = await axios.get<JobsDto>("/jobs/categories");
  return response.data;
};

export const useJobCategory = () => {
  const { error, isLoading: loading, data: jobCategories } = useQuery<JobsDto, Error>({queryKey: ["jobCategories"], queryFn: fetchCategories});

  return { jobCategories, loading, error };
};

