import React, { useState } from 'react';
import './GeneratedContentBox.css';
import { Button } from '@reactive-resume/ui';

interface GeneratedContentBoxProps {
  content: string;
}

const GeneratedContentBox: React.FC<GeneratedContentBoxProps> = ({ content }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(content);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <div style={{ position: 'relative', border: '1px solid #ccc', borderRadius: '1px', padding: '10px', marginBottom: '10px', maxHeight: '800px', overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: 'black', display: 'flex', flexDirection: 'column' }}>
      <div style={{ position: 'sticky', top: '5px', right: '5px', zIndex: 1, alignSelf: 'flex-end' }}>
        <Button onClick={handleCopy}>
          {copied ? 'Copied!' : 'Copy'}
        </Button>
      </div>
      <div style={{ height: '30px' }}></div>
      <div style={{ whiteSpace: 'pre-wrap' }}>{content}</div>
    </div>
  );
};

export default GeneratedContentBox;
