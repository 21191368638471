import { GetUsersDto } from "@reactive-resume/dto";
import { useQuery } from "@tanstack/react-query";
import { axios } from "@/client/libs/axios";

export const fetchAllUser = async (key: string, isAdmin: boolean) => {
  const response = await axios.post<GetUsersDto[]>("/user/all", {
    key,
    isAdmin
  });
  return response.data;
};

export const useGetAllUsers = (key: string, isAdmin: boolean) => {

  const { error, isLoading: loading, data } = useQuery<GetUsersDto[], Error>({
    queryKey: ["users"],
    queryFn: () => fetchAllUser(key, isAdmin),
  });

  return { users: data, loading, error };
};
