import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUser } from "@/client/services/user";

export const UserVerificationGuard = () => {
  const location = useLocation();
  const redirectTo = encodeURIComponent(location.pathname + location.search);
  const { user, loading } = useUser();

  if (loading) return null;

  if (!user) {
    return <Navigate to={`/auth/login?redirect=${redirectTo}`} replace />;
  }

  const isVerified = user.isVerified;

  if (!isVerified || user.isAdmin) {
    return <Navigate to="/dashboard" replace />;
  }

  return <Outlet />;
};
