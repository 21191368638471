import { axios } from "@/client/libs/axios";

export const addUserVerification = async (id: string, credits: number) => {
  try {
    const response = await axios.patch(`/user/add-user-credits`, {
      id,
      credits
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error updating user credits.`);
  }
};

export const subUserVerification = async (id: string, credits: number) => {
  try {
    const response = await axios.patch(`/user/sub-user-credits`, {
      id,
      credits
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error updating user credits.`);
  }
};
