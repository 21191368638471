import React, { useState } from 'react';
import './GeneratedContentBox.css';
import { TextArea } from '@reactive-resume/ui';

interface GeneratedContentBoxProps {
  content: string;
  onContentChange: (newContent: string) => void;
}

const GeneratedContentTextAreaBox: React.FC<GeneratedContentBoxProps> = ({ content, onContentChange }) => {

  return (
    <div style={{ border: '1px solid #ccc', borderRadius: '1px', padding: '10px', marginBottom: '10px', maxHeight: '800px', overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: 'black', display: 'flex', flexDirection: 'column' }}>

        <TextArea
          value={content}
          onChange={(e) => onContentChange(e.target.value)}
          style={{ width: '100%', minHeight: '400px' }}
        />
    </div>
  );
};

export default GeneratedContentTextAreaBox;
