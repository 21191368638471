import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUser } from "@/client/services/user";

export const AdminAuthGuard = () => {
  const location = useLocation();
  const redirectTo = encodeURIComponent(location.pathname + location.search);
  const { user, loading } = useUser();

  if (loading) return null;

  if (!user) {
    return <Navigate to={`/auth/login?redirect=${redirectTo}`} replace />;
  }

  const isAdmin = user.isAdmin;
  const isVerified = user.isVerified;

  if (!isAdmin || !isVerified) {
    return <Navigate to="/dashboard" replace />;
  }

  // const secretKey = user.secret_key;
  // const expectedSecretKey = import.meta.env.VITE_REACT_APP_ADMIN_SECRET_KEY;
  // if (secretKey !== expectedSecretKey) {
  //   return <Navigate to="/" replace />;
  // }

  return <Outlet />;
};
