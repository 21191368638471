import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "@lingui/macro";
import { Form, FormDescription, FormField, FormItem, FormLabel, Checkbox, Button, FormControl, FormMessage, Input, Combobox } from "@reactive-resume/ui";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useUpdateUser, useUser } from "@/client/services/user";
import { cn } from "@reactive-resume/utils";
import { useJobCategory } from "@/client/services/jobs-alert";
import { useGetJobByIdentifier } from "@/client/services/jobs-alert/get-jobs-by-identifier";
import GeneratedContentBox from "@/client/components/content-box/content-box";
import { toast } from "@/client/hooks/use-toast";
import { interviewPreparation } from "@/client/services/openai/interview-preparation";
import sgMail from '@sendgrid/mail';
import { useSendGrid } from "@/client/services/send-grid";
import { subUserVerification } from "@/client/services/user/update-user-credit";

const formSchema = z.object({
  emailFrequency: z.enum(["weekly", "bi_weekly", "monthly"]).default("weekly"),
  jobCategories: z.array(z.string()),
});

type FormValues = z.infer<typeof formSchema>;

export const FormInterviewPreparation = () => {
  const [selectedOption, setSelectedOption] = useState('dropdown');
  const { user } = useUser();
  const { updateUser, loading } = useUpdateUser();
  const { jobCategories } = useJobCategory();
  const { jobData } = useGetJobByIdentifier(user ? user.email : '');
  const [company, setCompany] = useState('');
  const [category, setCategory] = useState('');
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {sendingEmail} = useSendGrid();


  const form = useForm({
    // resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      category: "",
      company: "",
      d_title: "",
      d_category: "",
      d_company: "",
    },
  });

  useEffect(() => {
    user && onReset();
  }, [user]);

  const onReset = () => {
    if (!user) return;

    if(selectedOption == "dropdown") {
      form.reset({ d_title: "", d_company: "", d_category: "" });
      setCompany("");
      setCategory("");
    }

    if(selectedOption == "custom") {
      form.reset({ title: "", company: "", category: "" });
    }
  };

  const onSubmit = async (newData: any) => {
    if (!user) return;

    if(selectedOption == "dropdown") {
      onClick(newData.d_title, company, category);
    } else {
      onClick(newData.title, newData.company, newData.category);
    }
  };

  const handleJobTitleChange = (selectedJobId: string) => {
    const selectedJob = jobData?.find(job => String(job.job_title) === selectedJobId);
    if (selectedJob) {
      setCompany(selectedJob.company_name ?? '');
      setCategory(selectedJob.category ?? '')
    }
  };

  const onClick = async (title: string, company: string, category: string) => {
    setIsLoading(true);
    try {
      if(user?.credits && user.credits >= 75) {
        let result = await interviewPreparation(title, company, category);
        setContent(result);
        let crd = await subUserVerification(user.id, 75);
        await updateUser({
          credits: crd.credits,
        });
        setIsLoading(false);
      } else {
        toast({
          variant: "error",
          title: t`Oops, the server returned an error.`,
          description: `You don't have enough credits to generate interview questions. Please contact +592 650-6623 to purchase more.`,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast({
        variant: "error",
        title: t`Oops, the server returned an error.`,
        description: (error as Error)?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-2xl font-bold leading-relaxed tracking-tight">{`Interview Preparation`}</h3>
        <p className="leading-relaxed opacity-75">
          {`Select or enter your ideal job and get ready to ace your interviews with personalized preparation.`}
        </p>
      </div>

      <div>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              value="dropdown"
              checked={selectedOption === 'dropdown'}
              onChange={() => setSelectedOption('dropdown')}
              style={{ marginRight: '5px' }}
            />
            <span>&nbsp;&nbsp;</span>
            <span style={{ fontWeight: 'bold' }}>{`Select Job`}</span>
          </label>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              value="custom"
              checked={selectedOption === 'custom'}
              onChange={() => setSelectedOption('custom')}
              style={{ marginRight: '5px' }}
            />
            <span>&nbsp;&nbsp;</span>
            <span style={{ fontWeight: 'bold' }}>{`Custom Entry`}</span>
          </label>
        </div>

        <div style={{ height: '20px' }} />

        {/* Conditional rendering of form based on selected radio button */}
        {selectedOption === 'dropdown' && (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-2 sm:grid-cols-3">
                <FormField
                  name="d_title"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{`Select Job Title`}</FormLabel>
                      <div style={{ height: '5px' }} />
                      <div className="w-full">
                        <Combobox
                          {...field}
                          value={field.value}
                          onValueChange={(selectedValue) => {
                            field.onChange(selectedValue);
                            handleJobTitleChange(selectedValue);
                          }}
                          options={(jobData || []).map(job => ({ label: job.job_title, value: String(job.job_title) }))}
                        />
                      </div>
                    </FormItem>
                  )}
                />
              <FormField
                  name="d_company"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t`Company`}</FormLabel>
                      <div style={{ height: '5px' }} />
                      <FormControl>
                        <Input {...field} value={company} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
              />
              <FormField
                  name="d_category"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{`Category`}</FormLabel>
                      <div style={{ height: '5px' }} />
                      <FormControl>
                        <Input {...field} value={category} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
              />

              <div style={{ height: '5px' }} />

              <div className="sm:col-span-3 flex justify-end"> {/* Adjust the grid layout here */}
                <div
                  className={cn(
                    "hidden items-center space-x-2 self-center",
                    form.formState.isDirty && "flex animate-in fade-in",
                  )}
                >
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? "Loading..." : "Generate Questions"}
                  </Button>
                  <Button type="reset" variant="ghost" onClick={onReset}>
                    {t`Discard`}
                  </Button>
                </div>
              </div>
            </form>
            <div style={{ height: '15px '}}></div>
            {content!="" ?
              <div className="sm:col-span-3">
                <GeneratedContentBox content={content} />
              </div> : <></> }
          </Form>
        )}

        {selectedOption === 'custom' && (
          <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-2 sm:grid-cols-3">

              <FormField
                name="title"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{`Enter Job Title`}</FormLabel>
                    <div style={{ height: '5px' }} />
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="company"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t`Company`}</FormLabel>
                    <div style={{ height: '5px' }} />
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="category"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{`Category`}</FormLabel>
                    <div style={{ height: '5px' }} />
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

            <div style={{ height: '5px' }} />

            <div className="sm:col-span-3 flex justify-end"> {/* Adjust the grid layout here */}
              <div
                className={cn(
                  "hidden items-center space-x-2 self-center",
                  form.formState.isDirty && "flex animate-in fade-in",
                )}
              >
                <Button type="submit" disabled={isLoading}>
                  {isLoading ? "Loading..." : "Generate Questions"}
                </Button>
                <Button type="reset" variant="ghost" onClick={onReset}>
                  {t`Discard`}
                </Button>
              </div>
            </div>
          </form>

          <div style={{ height: '15px '}}></div>
          {content!="" ?
            <div className="sm:col-span-3">
              <GeneratedContentBox content={content} />
            </div> : <></> }
        </Form>

        )}
      </div>
    </div>
  );
};
