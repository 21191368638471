import { useGetJobByIdentifier } from "@/client/services/jobs-alert/get-jobs-by-identifier";
import ReactDataTables from "../../view-jobs/_section/ReactDataTables";
import { updateUserVerification, useUser } from "@/client/services/user";
import { cva } from "class-variance-authority";
import { useState } from "react";
import { Spinner } from "@phosphor-icons/react";
import { useGetAllUsers } from "@/client/services/user/get-all-users";
import { getInitials } from "@reactive-resume/utils";
import { AddCreditDialog } from "../_dialogs/addCredit";

declare global {
  interface Window {
    handleClick: (id: any, isVerified: any) => void;
    handleAddCredits: (userId: any, name: any) => void;
  }
}

// Define button variants
export const buttonVariants = cva(
  "inline-flex scale-100 items-center justify-center rounded-sm text-sm font-medium ring-offset-background transition-[transform,background-color] focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary focus-visible:ring-offset-2 active:scale-95 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        primary: "bg-primary text-primary-foreground hover:bg-primary/80",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        error: "bg-error text-error-foreground hover:bg-error/80",
        warning: "bg-warning text-warning-foreground hover:bg-warning/80",
        info: "bg-info text-info-foreground hover:bg-info/80",
        success: "bg-success text-success-foreground hover:bg-success/80",
        outline:
          "border border-secondary bg-transparent hover:bg-secondary hover:text-secondary-foreground",
        ghost: "hover:bg-secondary hover:text-secondary-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        sm: "h-8 px-4 text-xs",
        md: "h-9 px-5",
        lg: "h-10 px-6",
        icon: "size-9",
      },
    },
    compoundVariants: [
      { variant: "link", size: "sm", className: "h-auto px-0" },
      { variant: "link", size: "md", className: "h-auto px-0" },
      { variant: "link", size: "lg", className: "h-auto px-0" },
    ],
    defaultVariants: {
      variant: "primary",
      size: "md",
    },
  },
);

export const UsersViewSection = () => {
  const { user, loading } = useUser();
  const { users, loading: isLoading } = useGetAllUsers(user?.secret_key ?? "", user?.isAdmin ?? false);
  // const { open } = useDialog("add-credit");

  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState();

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const buttonClasses = buttonVariants({
    variant: "primary",
    size: "md",
  });

  const columns = [
    {
      title: "<div style='text-align: left;'>Sno</div>",
      render: (data: any, type: any, row: any, meta: any) => {
        return `<div style="text-align: left;">${(meta.row + 1).toString()}</div>`;
      },
    },
    {
      // data: "name",
      title: "Name",
      render: (data: any, type: any, row: any) => {
        const usr = row;
        let picture: React.ReactNode = null;

        if (!usr.picture) {
          const initials = getInitials(usr.name);

          picture = (
            `<div
              style="height:25px;width:25px;"
              className="flex items-center justify-center rounded-full bg-secondary text-center text-[10px] font-semibold text-secondary-foreground"
            >
              ${initials}
            </div>`
          );
        } else {
          picture = (
            `<img
              alt="${usr.name}"
              src="${usr.picture}"
              className="rounded-full"
              style="height:25px;width:25px;"
            />`
          );
        }

        return (
          `<div style="display: flex; align-items: center;">
            ${picture}
            <span style="margin-left: 8px;">${usr.name}</span>
          </div>`
        );
      },
    },
    { data: "email", title: "Email" },
    { data: "provider", title: "Login with" },
    {
      title: "<div style='text-align: left;'>Credits</div>",
      render: (data: any, type: any, row: any, meta: any) => {
        return `<div style="text-align: left;">${(row.credits).toString()}</div>`;
      },
    },
    {
      title: "Action",
      render: (data: any, type: any, row: any) => {
        const buttonText = row.isVerified ? "Restrict" : "Approve";
        const buttonClass = row.isVerified ? "background-color:red;color:white;border:none;padding:8px 16px;border-radius:4px;cursor:pointer;" : "background-color:green;color:white;border:none;padding:8px 16px;border-radius:4px;cursor:pointer;";
        const creditButtonClass = "margin-left:5px;background-color:black;color:white;border:none;padding:8px 16px;border-radius:4px;cursor:pointer;";

        window.handleClick = async (id: any, isVerified: any) => {
          await updateUserVerification(user?.secret_key ?? '', user?.isAdmin ?? false, id, isVerified);

          window.location.reload();
        };

        window.handleAddCredits = async (userId, name) => {
          // Add your logic to handle adding credits for the user
          console.log("User ID: ", userId)
          setName(name);
          setId(userId);
          openDialog();
        };

        return `<div>
          <button style='${buttonClass}' onClick='handleClick("${row.id}", ${!row.isVerified})'>${buttonText}</button>
          <button style='${creditButtonClass}' onClick='handleAddCredits("${row.id}", "${row.name}")'>Add Credits</button>
        </div>`;
      },
    },
  ];

  return(
    <>{isLoading ? <Spinner size={30} /> : <><AddCreditDialog isOpen={isOpen} close={closeDialog} userName={name} id={id} /><ReactDataTables data={users} columns={columns} pageLength={25} /></>}</>
  );
};
