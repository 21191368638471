import { GetUsersDto, UpdateUserDto, UserDto } from "@reactive-resume/dto";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { axios } from "@/client/libs/axios";
import { queryClient } from "@/client/libs/query-client";

export const updateUserVerification = async (key: string, isAdmin: boolean, id: string, isVerified: boolean) => {
  try {
    const response = await axios.patch(`/user/update-user-verification`, {
      key,
      isAdmin,
      id,
      isVerified
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error updating user`);
  }
};

// export const updateUserVerification = async (key: string, isAdmin: boolean, id: string, isVerified: boolean) => {
//   const response = await axios.patch<GetUsersDto>(
//     "/user/update-user-verification", {
//       key,
//       isAdmin,
//       id,
//       isVerified
//     }
//   );

//   return response.data;
// };

// export const useUpdateUserVerification = (key: string, isAdmin: boolean, id: string, isVerified: boolean) => {
//   const {
//     error,
//     isPending: loading,
//     mutateAsync: updateUserFn,
//   } = useMutation({
//     mutationFn: updateUserVerification,
//     onSuccess: (data) => {
//       // queryClient.setQueryData(["user"], data);
//       console.log("User updated");
//     },
//   });

//   return { updateUser: updateUserFn, loading, error };
// };
