import { t } from "@lingui/macro";
import { ScrollArea } from "@reactive-resume/ui";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import { JobsViewSection } from "./_section/jobs_view";

export const JobsViewPage = () => {

  return (
    <>
      <Helmet>
        <title>
          {`View Jobs`} - {`Career Forge`}
        </title>
      </Helmet>

      <div className="max-w-1xl space-y-4">
        <motion.h1
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          className="text-4xl font-bold tracking-tight"
        >
          {`View Jobs`}
        </motion.h1>

        <ScrollArea hideScrollbar className="h-[calc(100vh-140px)] lg:h-[calc(100vh-88px)]">
          <div className="">
            <JobsViewSection />
          </div>
        </ScrollArea>
      </div>
    </>
  );
};

