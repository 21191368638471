/* eslint-disable lingui/text-restrictions */

import axios from "axios";

const PROMPT = `You are an AI writing assistant specialized in writing copy for resumes.
Do not return anything else except the text you improved. It should not begin with a newline. It should not have any prefix or suffix text.
Improve the writing of the following paragraph and returns in the language of the text:

Text: """{input}"""

Revised Text: """`;

export const improveWriting = async (text: string) => {
  const prompt = PROMPT.replace("{input}", text);

  const response = await axios.post(
    "/api/openai/send",
    {prompt},
  );

  return response.data ?? text;
};
