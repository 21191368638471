import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "@lingui/macro";
import { Form, FormDescription, FormField, FormItem, FormLabel, Checkbox, Button, FormControl, FormMessage, Input, Combobox, TextArea } from "@reactive-resume/ui";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useUpdateUser, useUser } from "@/client/services/user";
import { cn } from "@reactive-resume/utils";
import { useJobCategory } from "@/client/services/jobs-alert";
import { useGetJobByIdentifier } from "@/client/services/jobs-alert/get-jobs-by-identifier";
import { coverLetter } from "@/client/services/openai/cover-letter";
import { toast } from "@/client/hooks/use-toast";
import GeneratedContentBox from "@/client/components/content-box/content-box";
import { useDummyResume } from "@/client/services/resume";
import { subUserVerification } from "@/client/services/user/update-user-credit";


const formSchema = z.object({
  emailFrequency: z.enum(["weekly", "bi_weekly", "monthly"]).default("weekly"),
  jobCategories: z.array(z.string()),
});

type FormValues = z.infer<typeof formSchema>;

export const FormCoverLetter = () => {
  const [selectedOption, setSelectedOption] = useState('dropdown');
  const { user } = useUser();
  const { updateUser, loading } = useUpdateUser();
  const { jobCategories } = useJobCategory();
  const { jobData } = useGetJobByIdentifier(user ? user.email : '');
  const [company, setCompany] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState('');
  const { resume } = useDummyResume(user ? user.username : '', 'ignorethisitem-ignore');


  const form = useForm({
    // resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      description: "",
      company: "",
      d_title: "",
      d_description: "",
      d_company: "",
    },
  });

  useEffect(() => {
    console.log(jobData, 'jobData')
    user && onReset();
  }, [user, jobData]);

  const onReset = () => {
    if (!user) return;

    if(selectedOption == "dropdown") {
      form.reset({ d_title: "", d_description: "", d_company: "" });
      setCompany("");
    }

    if(selectedOption == "custom") {
      form.reset({ title: "", description: "", company: "" });
    }
  };

  const onSubmit = async (newData: any) => {
    if (!user) return;

    if(selectedOption == "dropdown") {
      onClick(newData.d_title, company, newData.d_description);
    } else {
      onClick(newData.title, newData.company, newData.description);
    }
  };

  const handleJobTitleChange = (selectedJobId: string) => {
    const selectedJob = jobData?.find(job => String(job.job_title) === selectedJobId);
    if (selectedJob) {
      setCompany(selectedJob.company_name ?? '');
    }
  };

  const onClick = async (title: string, company: string, description: string) => {
    setIsLoading(true);
    try {
      if(user?.credits && user.credits >= 75) {
        let info = getUserInfo();

        let result = await coverLetter(title, company, description, info);
        setContent(result);
        let crd = await subUserVerification(user.id, 75);
        await updateUser({
          credits: crd.credits,
        });
        setIsLoading(false);
      } else {
        toast({
          variant: "error",
          title: t`Oops, the server returned an error.`,
          description: `Not enough credits to generate a cover letter. Please contact +592 650-6623 to buy more.`,
        });
        setIsLoading(false);
      }

    } catch (error) {
      toast({
        variant: "error",
        title: t`Oops, the server returned an error.`,
        description: (error as Error)?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getUserInfo = () => {
    let fullName = resume?.data.basics.name != '' ? `**Full Name:** ${resume?.data.basics.name}\n\n` : '';
    let summary = resume?.data.sections.summary.content != '' ? `**Summary:** ${resume?.data.sections.summary.content}\n\n`: '';
    let experience = resume?.data.sections.experience.items.length!=0 ? `**Experience**\n\n${getUserExperience()}`: '';
    let education = resume?.data.sections.education.items.length!=0 ? `**Education**\n\n${getUserEducation()}`: '';
    let skills = resume?.data.sections.skills.items.length!=0 ? `**Skills**\n\n${getUserSkills()}` : '';
    let awards = resume?.data.sections.awards.items.length!=0 ? `**Awards**\n\n${getUserAwards()}`: '';

    let userInfo = `${fullName}${summary}${experience}${education}${skills}${awards}`

    return userInfo;
  }

  const getUserExperience = () => {
    let item = ``;
    resume?.data.sections.experience.items.forEach((element, index) => {
      let company = element.company!='' ? `Company: ${element.company}\n` : '';
      let position = element.position!='' ? `Position: ${element.position}\n` : '';
      let location = element.location!='' ? `Location: ${element.location}\n` : '';
      let summary = element.summary!='' ? `Summary: ${element.summary}\n` : '';
      item+=`Experience ${index+1}:\n\n${company}${position}${location}${summary}\n`
    });
    return item;
  }

  const getUserEducation = () => {
    let item = ``;
    resume?.data.sections.education.items.forEach((element, index) => {
      let institution = element.institution!='' ? `Institution: ${element.institution}\n` : '';
      let studyType = element.studyType!='' ? `Study Type: ${element.studyType}\n` : '';
      let area = element.area!='' ? `Area of Study: ${element.area}\n` : '';
      let summary = element.summary!='' ? `Summary: ${element.summary}\n` : '';
      item+=`Education ${index+1}:\n\n${institution}${studyType}${area}${summary}\n`
    });
    return item;
  }

  const getUserSkills = () => {
    let item = ``;
    resume?.data.sections.skills.items.forEach((element, index) => {
      let name = element.name!='' ? `Name: ${element.name}\n` : '';
      let level = element.level!=0 ? `Skill Level: ${element.level}\n` : '';
      let description = element.description!='' ? `Description: ${element.description}\n` : '';
      item+=`Skill ${index+1}:\n\n${name}${level}${description}\n`
    });
    return item;
  }

  const getUserAwards = () => {
    let item = ``;
    resume?.data.sections.awards.items.forEach((element, index) => {
      let title = element.title!='' ? `Award Title: ${element.title}\n` : '';
      let awarder = element.awarder!='' ? `Awarder: ${element.awarder}\n` : '';
      let date = element.date!='' ? `Award Date: ${element.date}\n` : '';
      let summary = element.summary!='' ? `Summary: ${element.summary}\n` : '';
      item+=`Award ${index+1}:\n\n${title}${awarder}${date}${summary}\n`
    });
    return item;
  }


  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-2xl font-bold leading-relaxed tracking-tight">{`Create Cover Letter`}</h3>
        <p className="leading-relaxed opacity-75">
          {`Generate a professional cover letter tailored to your job.`}
        </p>
      </div>

      <div>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              value="dropdown"
              checked={selectedOption === 'dropdown'}
              onChange={() => setSelectedOption('dropdown')}
              style={{ marginRight: '5px' }}
            />
            <span>&nbsp;&nbsp;</span>
            <span style={{ fontWeight: 'bold' }}>{`Select Job`}</span>
          </label>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              value="custom"
              checked={selectedOption === 'custom'}
              onChange={() => setSelectedOption('custom')}
              style={{ marginRight: '5px' }}
            />
            <span>&nbsp;&nbsp;</span>
            <span style={{ fontWeight: 'bold' }}>{`Custom Entry`}</span>
          </label>
        </div>

        <div style={{ height: '20px' }} />

        {/* Conditional rendering of form based on selected radio button */}
        {selectedOption === 'dropdown' && (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-6 sm:grid-cols-2">
              <FormField
                name="d_title"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{`Select Job Title`}</FormLabel>
                    <div style={{ height: '5px' }} />
                    <div className="w-full">
                      <Combobox
                        {...field}
                        value={field.value}
                        onValueChange={(selectedValue) => {
                          field.onChange(selectedValue);
                          handleJobTitleChange(selectedValue);
                        }}
                        options={(jobData || []).map(job => ({ label: job.job_title, value: String(job.job_title) }))}
                      />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                  name="d_company"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t`Company`}</FormLabel>
                      <div style={{ height: '5px' }} />
                      <FormControl>
                        <Input {...field} value={company} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
              />

              <div className={cn("sm:col-start-1 sm:col-span-2 flex justify-start", form.formState.isDirty && "flex animate-in fade-in")}>
                <FormField
                  name="d_description"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t`Description`}</FormLabel>
                      <div style={{ height: '5px' }} />
                      <FormControl>
                        <TextArea {...field} rows={100} style={{height: 150, paddingTop: '10px'}} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="sm:col-span-2 flex justify-end">
                <div
                  className={cn(
                    "hidden items-center space-x-2 self-center",
                    form.formState.isDirty && "flex animate-in fade-in",
                  )}
                >
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? "Loading..." : "Generate Cover Letter"}
                  </Button>
                  <Button type="reset" variant="ghost" onClick={onReset}>
                    {t`Discard`}
                  </Button>
                </div>
              </div>
            </form>
            <div style={{ height: '15px '}}></div>
            {content!="" ?
              <div className="sm:col-span-2">
                <GeneratedContentBox content={content} />
              </div> : <></> }
          </Form>
        )}

        {selectedOption === 'custom' && (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-6 sm:grid-cols-2">
              <FormField
                  name="title"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{`Job Title`}</FormLabel>
                      <div style={{ height: '5px' }} />
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
              />
              <FormField
                  name="company"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t`Company`}</FormLabel>
                      <div style={{ height: '5px' }} />
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
              />

              <div className={cn("sm:col-start-1 sm:col-span-2 flex justify-start", form.formState.isDirty && "flex animate-in fade-in")}>
                <FormField
                  name="description"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t`Description`}</FormLabel>
                      <div style={{ height: '5px' }} />
                      <FormControl>
                        <TextArea {...field} rows={100} style={{height: 150, paddingTop: '10px'}} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="sm:col-span-2 flex justify-end">
                <div
                  className={cn(
                    "hidden items-center space-x-2 self-center",
                    form.formState.isDirty && "flex animate-in fade-in",
                  )}
                >
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? "Loading..." : "Generate Cover Letter"}
                  </Button>
                  <Button type="reset" variant="ghost" onClick={onReset}>
                    {t`Discard`}
                  </Button>
                </div>
              </div>
            </form>
            <div style={{ height: '15px '}}></div>
            {content!="" ?
              <div className="sm:col-span-2">
                <GeneratedContentBox content={content} />
              </div> : <></> }
          </Form>
        )}
      </div>
    </div>
  );
};
