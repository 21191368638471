import { useGetJobByIdentifier } from "@/client/services/jobs-alert/get-jobs-by-identifier";
import ReactDataTables from "./ReactDataTables";
import { useUser } from "@/client/services/user";
import { cva } from "class-variance-authority";
import { useEffect, useState } from "react";
import { Spinner } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

// Define button variants
export const buttonVariants = cva(
  "inline-flex scale-100 items-center justify-center rounded-sm text-sm font-medium ring-offset-background transition-[transform,background-color] focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary focus-visible:ring-offset-2 active:scale-95 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        primary: "bg-primary text-primary-foreground hover:bg-primary/80",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        error: "bg-error text-error-foreground hover:bg-error/80",
        warning: "bg-warning text-warning-foreground hover:bg-warning/80",
        info: "bg-info text-info-foreground hover:bg-info/80",
        success: "bg-success text-success-foreground hover:bg-success/80",
        outline:
          "border border-secondary bg-transparent hover:bg-secondary hover:text-secondary-foreground",
        ghost: "hover:bg-secondary hover:text-secondary-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        sm: "h-8 px-4 text-xs",
        md: "h-9 px-5",
        lg: "h-10 px-6",
        icon: "size-9",
      },
    },
    compoundVariants: [
      { variant: "link", size: "sm", className: "h-auto px-0" },
      { variant: "link", size: "md", className: "h-auto px-0" },
      { variant: "link", size: "lg", className: "h-auto px-0" },
    ],
    defaultVariants: {
      variant: "primary",
      size: "md",
    },
  },
);

export const JobsViewSection = () => {
  const { user } = useUser();
  const { jobData, loading } = useGetJobByIdentifier(user ? user.email : "");
  const [viewJobData, setViewJobData] = useState<boolean>(true);
  const navigate = useNavigate();

  const buttonClasses = buttonVariants({
    variant: "primary",
    size: "md",
  });

  useEffect(() => {
    // Define applyJob function in the global scope
    (window as any).applyJob = (email: string, jobTitle: string, companyName: string, jobId: number, jobDesc: string, jobUrl: string) => {
      // console.log('Applying for job:', email, jobTitle, companyName);
      navigate("/dashboard/send-application", {
        state: { jobTitle, companyName, email, jobId, jobDesc, jobUrl }
      });
    };
  }, []);

  // const applyJob = (email: string) => {
  //   console.log('applying for job');
  // }

  const columns = [
    {
      title: "<div style='text-align: left;'>Sno</div>",
      render: (data: any, type: any, row: any, meta: any) => {
        return `<div style="text-align: left;">${(meta.row + 1).toString()}</div>`;
      },
    },
    { data: "job_title", title: "Title" },
    { data: "category", title: "Category" },
    {
      title: "Company",
      data: "company_name"
      // render: (data: any, type: any, row: any) => {
      //   return `<a href="${row.web_site}" target="_blank" class="text-primary underline">${row.company_name}</a>`;
      // },
    },
    {
      data: "email",
      title: "Email",
      render: (data: any, type: any, row: any) => {
        return `<div style="text-align: left;">${data}</div>`; // Apply inline style for left alignment
      },
    },
    {
      data: "end_date",
      title: "Application End Date",
      render: (data: any, type: any, row: any) => {
        return `<div style="text-align: left;">${data}</div>`; // Apply inline style for left alignment
      },
    },
    {
      title: "Action",
      render: (data: any, type: any, row: any) => {
        let applyButton = ``;
        let applyText = row.applied ? `Applied` : `Apply`;
        let disabled = row.applied ? 'disabled' : '';
        // if (row.email !== "N/A") {
          applyButton = `<button class="${buttonClasses}" ${disabled} onclick="applyJob('${row.email}', '${row.job_title}', '${row.company_name}', '${row.id}', '${row.job_desc}', '${row.url}')">${applyText}</button>`;
        // }

        // ${applyButton ? ' ' + applyButton : ''}
        return `<div>
                  <button class="${buttonClasses}" onclick="window.open('${row.url}', '_blank')">View Details</button>  
                  ${applyButton}
                </div>`;
      },
    },
  ];

  return(
    <>{loading ? <Spinner size={30} /> : <ReactDataTables data={jobData} columns={columns} pageLength={25} />}</>
  );
};
