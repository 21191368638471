import { useGetJobByIdentifier } from "@/client/services/jobs-alert/get-jobs-by-identifier";
import { updateUser, useUpdateUser, useUser } from "@/client/services/user";
import { t } from "@lingui/macro";
import { Form, FormField, FormItem, FormLabel, Combobox, Button, FormControl, FormMessage, Input } from "@reactive-resume/ui";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "@/client/hooks/use-toast";
import { useSendGrid } from "@/client/services/send-grid";
import { cn } from "@reactive-resume/utils";
import { useJobCategory } from "@/client/services/jobs-alert";


export const FormInterviewProposal = () => {
  const { user } = useUser();
  const { jobData } = useGetJobByIdentifier(user ? user.email : '');
  const [company, setCompany] = useState('');
  const [category, setCategory] = useState('');
  const { sendingEmail, loading, error } = useSendGrid();
  const { updateUser } = useUpdateUser();
  const { jobCategories } = useJobCategory();

  const form = useForm<any>({
    defaultValues: {
      title: "",
      interviewDates: ["", "", ""],
      company: "",
      category: "",
    },
  });

  useEffect(() => {
    user && onReset();
  }, [user]);

  const onReset = () => {
    if (!user) return;

    form.reset({
      title: "",
      interviewDates: ["", "", ""],
      company: "",
      category: "",
    });

    setCompany('');
    setCategory('');
  };

  const onSubmit = async (newData: any) => {
    if (!user) return;

    if(newData.title && newData.interviewDates.every((date: any) => date !== "") && company && category)  {
      if(user.interviewDates.length!=0) {
        toast({
          variant: "error",
          title: `You have already requested an interview.`,
        });
      } else {
        await sendMail(newData);
      }

    } else {
      toast({
        variant: "error",
        title: `Error! Missing job title or interview dates.`,
      });
    }

  };

  const handleJobTitleChange = (selectedJobId: string) => {
    let sp = selectedJobId.split("-");
    const selectedJob = jobData?.find(job => String(job.job_title) === sp[0]);
    if (selectedJob) {
      setCompany(selectedJob.company_name ?? '');
      setCategory(selectedJob.category ?? '')
    }
  };

  const handleDateChange = (index: any, date: any) => {
    const { interviewDates } = form.getValues();

    // Check if the selected date is not null and not in the past
    if (date) {
      let adjustedDate = new Date(date);

      if (adjustedDate <= new Date()) {
        // If the selected date is in the past or present, adjust it to be in the future
        adjustedDate = new Date(Date.now() + 15 * 60000); // Adjusting to 15 minutes ahead
      }

      // Check if the adjusted date is unique among the previously selected dates
      const isUnique = interviewDates.every((existingDate: any, i: any) => i === index || !existingDate || +existingDate !== +adjustedDate);

      if (isUnique) {
        const updatedDates = [...interviewDates];
        updatedDates[index] = adjustedDate;
        form.setValue('interviewDates', updatedDates);
      } else {
        toast({
          variant: "warning",
          title: `Warning! Same date has already been selected.`,
        });
      }
    } else {
      toast({
        variant: "error",
        title: `Error! Invalid date selected.`,
      });
    }
  };

  const sendMail = async (newData: any) => {
    try {
      let b = `We have received a new request for a practice
      interview from a CareerForge user. Below are the details of the request,
      including the user's information and proposed dates for the practice interview.
      <br/><br/>
      <strong>User Information:</strong><br /><br />
      <strong>Name:</strong> ${user?.name}<br />
      <strong>Email:</strong> ${user?.email}<br />
      <strong>Phone Number:</strong> ${user?.data.basics.phone ?? 'N/A'}<br />
      <strong>Job Selected:</strong> ${newData.title.split("-")[0]}, ${company}<br /><br />
      <strong>Proposed Dates for Practice Interview:</strong><br /><br />
      <strong>First Choice:</strong> ${newData.interviewDates[0]}<br />
      <strong>Second Choice:</strong> ${newData.interviewDates[1]}<br />
      <strong>Third Choice:</strong> ${newData.interviewDates[2]}<br /><br />
      Regards,<br />
      CareerForge team`;

      const data = {
        subject: `New Practice Interview Request from ${user?.name}`,
        body: b,
        email: 'drewkatski@gmail.com',
      }
      await sendingEmail(data);
      let d = [`${newData.interviewDates[0]}`,`${newData.interviewDates[1]}`,`${newData.interviewDates[2]}`]
      await updateUser({
        interviewDates: d,
      });
      toast({
        variant: "success",
        title: `Email sent successfully!`,
      });
      onReset();
    } catch (error) {
      toast({
        variant: "error",
        title: `Some error occured!`,
      });
    }
  }

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-2xl font-bold leading-relaxed tracking-tight">{`Schedule Interview`}</h3>
        <p className="leading-relaxed opacity-75">
          {`Please select three dates of your choice for your upcoming interview. We'll do our best to accommodate your schedule.`}
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-6 sm:grid-cols-3">

          {/* Job Title and Company Fields */}
          <div className="sm:col-span-3 flex gap-6 justify-between">
            <div className="sm:w-1/2">
              <FormField
                name="title"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{`Select Job Title`}</FormLabel>
                    <div style={{ height: '1px' }} />
                    <div className="w-full max-w-full ">
                      <Combobox
                        {...field}
                        value={field.value}
                        onValueChange={(selectedValue) => {
                          field.onChange(selectedValue);
                          handleJobTitleChange(selectedValue);
                        }}
                        options={(jobData || []).map(job => ({ label: job.job_title, value: `${job.job_title+"-"+String(job.id)}` }))}
                      />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div className="sm:w-1/2">
              <FormField
                name="company"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t`Company`}</FormLabel>
                    <div style={{ height: '1px' }} />
                    {/* <div style={{ height: '5px' }} /> */}
                    <FormControl>
                      <Input {...field} value={company} className="" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          {/* <div style={{ height: '15px' }}></div> */}
          {/* Interview Date Fields */}
          <div className="sm:col-span-3 flex gap-5 justify-between">
            {[0, 1, 2].map(index => (
              <div key={index} className="sm:w-1/3">
                <FormField
                  name={`interviewDates`}
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{`Select Interview Date ${index + 1}`}</FormLabel>
                      <div style={{ height: '1px' }} />
                      <div className="w-full">
                        <DatePicker
                          wrapperClassName="w-full"
                          selected={field.value[index] || null}
                          onChange={(date) => handleDateChange(index, date)}
                          showTimeSelect
                          timeIntervals={15}
                          dateFormat="MMMM d, yyyy h:mm aa"
                          minDate={new Date()}
                          popperPlacement="bottom-end"
                          className={cn(
                            "flex h-9 w-full rounded border border-border bg-transparent px-3 py-0.5 !text-sm ring-0 ring-offset-transparent transition-colors [appearance:textfield] placeholder:opacity-80 hover:bg-secondary/20 focus:border-primary focus:bg-secondary/20 focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
                            "file:border-0 file:bg-transparent file:pt-1 file:text-sm file:font-medium file:text-primary",
                          )}

                        />
                      </div>
                    </FormItem>
                  )}
                />
              </div>
            ))}
          </div>

          {/* <div style={{ height: '8px' }}></div> */}
          {/* Buttons */}
          <div className="sm:col-span-3 flex justify-end">
            <div
              className={cn(
                "hidden items-center space-x-2 self-center",
                form.formState.isDirty && "flex animate-in fade-in",
              )}
            >
              <Button type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
              </Button>
              <Button type="reset" variant="ghost" onClick={onReset}>
                {`Discard`}
              </Button>
              {error && <div>Error: {error.message}</div>}
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}
