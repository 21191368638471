import { createZodDto } from "nestjs-zod/dto";

import { userSchema } from "./user";

export const getUsersSchema = userSchema.partial().pick({
  id: true,
  name: true,
  email: true,
  picture: true,
  provider: true,
  isVerified: true,
  credits: true,
});

export class GetUsersDto extends createZodDto(getUsersSchema) {}
