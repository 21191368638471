import { useLocation, useNavigate } from "react-router-dom";
import { Form, FormDescription, FormField, FormItem, FormLabel, Checkbox, Button, FormControl, FormMessage, Input, Combobox, TextArea } from "@reactive-resume/ui";
import { cn } from "@reactive-resume/utils";
import { useForm } from "react-hook-form";
import { t } from "@lingui/macro";
import { useUpdateUser, useUser } from "@/client/services/user";
import { toast } from "@/client/hooks/use-toast";
import { sendingEmail, downloadResume, downloadCoverLetter } from "@/client/services/send-grid";
import { useEffect, useState } from "react";
import { useDummyResume, useResumes, findResumeById } from "@/client/services/resume";
import GeneratedContentTextAreaBox from "@/client/components/content-box/content-text-area";
import { coverLetter } from "@/client/services/openai/cover-letter";
import { subUserVerification } from "@/client/services/user/update-user-credit";
import { Spinner } from "@phosphor-icons/react";
import { useInsertUserJobs } from "@/client/services/user-jobs";

export const SendApplicationSection = () => {
  const location = useLocation();
  const { jobTitle, companyName, email, jobId, jobDesc, jobUrl } = location.state;
  const { user } = useUser();
  const { resumes, loading } = useResumes();
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { resume } = useDummyResume(user ? user.username : '', 'ignorethisitem-ignore');
  const { updateUser, loading: load } = useUpdateUser();
  const { insertUserJobs, loading: ld } = useInsertUserJobs();
  const navigate = useNavigate();
  const [emailLoader, setEmailLoader] = useState(false);
  const [resLoader, setResLoader] = useState(false);


  const form = useForm({
    defaultValues: {
      title: "",
      company: "",
      res: "",
      email: ""
    },
  });

  useEffect(() => {
    console.log(email,jobUrl, "emailllll");
    const timeout = setTimeout(() => getCoverLetter(), 1000);

    return () => clearTimeout(timeout);
  }, []);

  const onReset = () => {
    if (!user) return;
  };

  const getCoverLetter = async () => {
    try {
      if (user?.credits && user.credits >= 75) {
        let info = getUserInfo();

        let result = await coverLetter(jobTitle, companyName, jobDesc != 'N/A' ? jobDesc : '', info);
        setContent(result);
        let crd = await subUserVerification(user.id, 75);
        await updateUser({
          credits: crd.credits,
        });
        setIsLoading(false);
      } else {
        toast({
          variant: "error",
          title: `Cover letter has not been generated.`,
          description: `Not enough credits to generate a cover letter. Please contact +592 650-6623 to buy more.`,
        });
        setContent('');
        setIsLoading(false);
      }
    } catch (error) {
      toast({
        variant: "error",
        title: t`Oops, the server returned an error.`,
        description: (error as Error)?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getUserInfo = () => {
    let fullName = resume?.data.basics.name != '' ? `**Full Name:** ${resume?.data.basics.name}\n\n` : '';
    let summary = resume?.data.sections.summary.content != '' ? `**Summary:** ${resume?.data.sections.summary.content}\n\n` : '';
    let experience = resume?.data.sections.experience.items.length != 0 ? `**Experience**\n\n${getUserExperience()}` : '';
    let education = resume?.data.sections.education.items.length != 0 ? `**Education**\n\n${getUserEducation()}` : '';
    let skills = resume?.data.sections.skills.items.length != 0 ? `**Skills**\n\n${getUserSkills()}` : '';
    let awards = resume?.data.sections.awards.items.length != 0 ? `**Awards**\n\n${getUserAwards()}` : '';

    let userInfo = `${fullName}${summary}${experience}${education}${skills}${awards}`

    return userInfo;
  }

  const getUserExperience = () => {
    let item = ``;
    resume?.data.sections.experience.items.forEach((element, index) => {
      let company = element.company != '' ? `Company: ${element.company}\n` : '';
      let position = element.position != '' ? `Position: ${element.position}\n` : '';
      let location = element.location != '' ? `Location: ${element.location}\n` : '';
      let summary = element.summary != '' ? `Summary: ${element.summary}\n` : '';
      item += `Experience ${index + 1}:\n\n${company}${position}${location}${summary}\n`
    });
    return item;
  }

  const getUserEducation = () => {
    let item = ``;
    resume?.data.sections.education.items.forEach((element, index) => {
      let institution = element.institution != '' ? `Institution: ${element.institution}\n` : '';
      let studyType = element.studyType != '' ? `Study Type: ${element.studyType}\n` : '';
      let area = element.area != '' ? `Area of Study: ${element.area}\n` : '';
      let summary = element.summary != '' ? `Summary: ${element.summary}\n` : '';
      item += `Education ${index + 1}:\n\n${institution}${studyType}${area}${summary}\n`
    });
    return item;
  }

  const getUserSkills = () => {
    let item = ``;
    resume?.data.sections.skills.items.forEach((element, index) => {
      let name = element.name != '' ? `Name: ${element.name}\n` : '';
      let level = element.level != 0 ? `Skill Level: ${element.level}\n` : '';
      let description = element.description != '' ? `Description: ${element.description}\n` : '';
      item += `Skill ${index + 1}:\n\n${name}${level}${description}\n`
    });
    return item;
  }

  const getUserAwards = () => {
    let item = ``;
    resume?.data.sections.awards.items.forEach((element, index) => {
      let title = element.title != '' ? `Award Title: ${element.title}\n` : '';
      let awarder = element.awarder != '' ? `Awarder: ${element.awarder}\n` : '';
      let date = element.date != '' ? `Award Date: ${element.date}\n` : '';
      let summary = element.summary != '' ? `Summary: ${element.summary}\n` : '';
      item += `Award ${index + 1}:\n\n${title}${awarder}${date}${summary}\n`
    });
    return item;
  }

  const sendMail = async (newData: any) => {
    if (newData.res != "" && content != "") {
      setEmailLoader(true);
      try {
        let r: any = await findResumeById({ id: newData.res });

        let b = `Good day, <br /><br />I am writing to apply for the ${jobTitle} position at ${companyName}. Please find my cover letter and resume attached below.
        <br/><br/>
        Best regards,<br />
        ${user?.name}`;

        const data = {
          subject: `${jobTitle} Application - ${user?.name}`,
          body: b,
          email: email,
          bcc: [user?.email, "careerforgeguyana@gmail.com"],
          from: {
            email: "guyana-applications@careerforges.com",
            name: user?.name
          },
          replyTo: {
            email: user?.email,
            name: user?.name
          },
          content: content,
          userResume: r.data,
        }
        await sendingEmail(data);
        await insertUserJobs({ jobId: parseInt(jobId), userId: user?.id });

        toast({
          variant: "success",
          title: `Email sent successfully!`,
        });
        setEmailLoader(false);
        handleNavigate();
      } catch (error) {
        setEmailLoader(false);
        toast({
          variant: "error",
          title: `Some error occured!`,
        });
      }
    } else {
      toast({
        variant: "error",
        title: `Resume or Cover Letter can't be empty!`,
      });
    }

  }

  const handleContentChange = (newContent: string) => {
    setContent(newContent);
  };

  const handleNavigate = () => {
    navigate('/dashboard/view-jobs', { replace: true });
    window.location.reload();
  };

  const handleDownloadResume = async () => {
    setResLoader(true);
    const data = form.getValues();
    if (!data.res) {
      toast({
        variant: "error",
        title: `No resume selected!`,
      });
      setResLoader(false);
      return;
    }

    try {
      let resumeData = await findResumeById({ id: data.res });
      await downloadResume(resumeData.data);
      toast({
        variant: "success",
        title: `Resume downloaded successfully!`,
      });
      setResLoader(false);
    } catch (error) {
      toast({
        variant: "error",
        title: `Error downloading files.`,
        description: (error as Error)?.message,
      });
      setResLoader(false);
    }
  };

  const handleDownloadCoverLetter = async () => {
    if (content === "") {
      toast({
        variant: "error",
        title: `No cover letter created!`,
      });
      return;
    }

    try {
      await downloadCoverLetter(content);
      toast({
        variant: "success",
        title: `Cover Letter downloaded successfully!`,
      });
    } catch (error) {
      toast({
        variant: "error",
        title: `Error downloading files.`,
        description: (error as Error)?.message,
      });
    }
  };

  const handleJobUrl = async () => {
    window.open(jobUrl, '_blank');
  }

  return (
    <>
      {isLoading ?
        <Spinner size={30} />
        :
        <div className="space-y-6">
          <div>
            <h3 className="text-2xl font-bold leading-relaxed tracking-tight">{`Job Application`}</h3>
            <p className="leading-relaxed opacity-75">
              {`Send your application to the job you desired in just one click!`}
            </p>
          </div>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(sendMail)} className="grid gap-6 sm:grid-cols-3">
              <div className="sm:col-span-1">
                <FormField
                  name="title"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{`Job Title`}</FormLabel>
                      <div style={{ height: '5px' }} />
                      <FormControl>
                        <Input value={jobTitle} readOnly disabled />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="sm:col-span-1">
                <FormField
                  name="company"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t`Company`}</FormLabel>
                      <div style={{ height: '5px' }} />
                      <FormControl>
                        <Input value={companyName} readOnly disabled />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="sm:col-span-2">
                <FormField
                  name="res"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{`Select Resume`}</FormLabel>
                      <div style={{ height: '5px' }} />
                      <div className="w-full">
                        <Combobox
                          {...field}
                          value={field.value}
                          onValueChange={(selectedValue) => {
                            field.onChange(selectedValue);
                          }}
                          options={(resumes || [])
                            .filter((resume) => resume.title !== "ignorethisitem-ignore")
                            .map((resume) => ({ label: resume.title, value: String(resume.id) }))}
                        />
                      </div>
                    </FormItem>
                  )}
                />
              </div>

              <div className="sm:col-span-2">
                <FormLabel>{`Cover Letter`}</FormLabel>
                <div style={{ height: '15px' }} />
                <GeneratedContentTextAreaBox content={content} onContentChange={handleContentChange} />
              </div>

              <div className="sm:col-span-3 flex space-x-4">
                {email === "N/A" ?
                  <Button type="button" onClick={handleJobUrl}>
                    {`Go to Job Application`}
                  </Button> :
                  <Button type="submit" disabled={emailLoader}>
                    {`Send Application`}
                  </Button>}

                <Button type="button" onClick={handleNavigate}>
                  {`Browse More Jobs`}
                </Button>

                {email === "N/A" ?
                  <>
                    <Button type="button" onClick={handleDownloadResume} disabled={resLoader}>
                      {`Download Resume`}
                    </Button>
                    <Button type="button" onClick={handleDownloadCoverLetter}>
                      {`Download Cover Letter`}
                    </Button>
                  </> : <></>}
              </div>
            </form>
          </Form>
        </div>
      }
    </>

  );
};


{/* <div className="space-y-6">
      <div>
        <h3 className="text-2xl font-bold leading-relaxed tracking-tight">{`Job Application`}</h3>
        <p className="leading-relaxed opacity-75">
          {`Send your application to the job you desired in just one click!`}
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(sendMail)} className="grid gap-6 sm:grid-cols-3">
          <FormField
              name="title"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{`Job Title`}</FormLabel>
                  <div style={{ height: '5px' }} />
                  <FormControl>
                    <Input value={jobTitle} readOnly disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
          />
          <FormField
              name="company"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t`Company`}</FormLabel>
                  <div style={{ height: '5px' }} />
                  <FormControl>
                    <Input value={companyName} readOnly disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
          />

          <FormField
            name="resume"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{`Select Resume`}</FormLabel>
                <div style={{ height: '5px' }} />
                <div className="w-full">
                  <Combobox
                    {...field}
                    value={field.value}
                    onValueChange={(selectedValue) => {
                      field.onChange(selectedValue);
                      // handleJobTitleChange(selectedValue);
                    }}
                    options={(resumes || [])
                      .filter((resume) => resume.title !== "ignorethisitem-ignore")
                      .map((resume) => ({ label: resume.title, value: String(resume.id) }))}
                  />
                </div>
              </FormItem>
            )}
          />

          <div className="sm:col-span-2">
            <FormLabel>{`Cover Letter`}</FormLabel>
            <div style={{height:15}}></div>
            <GeneratedContentTextAreaBox content={content} />
          </div>

          <Button type="submit">
            {t`Save Changes`}
          </Button>
        </form>
      </Form>
    </div> */}
