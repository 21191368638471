import { t } from "@lingui/macro";
import { ScrollArea, Separator } from "@reactive-resume/ui";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import { BasicsUserProfile } from './_sections/basics';
import { ProfilesUserProfile } from "./_sections/profiles";
import { SummaryUserProfile } from "./_sections/summary";
import { ExperienceUserProfile } from "./_sections/experience";
import { EducationUserProfile } from "./_sections/education";
import { SkillsUserProfile } from "./_sections/skills";
import { LanguagesUserProfile } from "./_sections/languages";
import { AwardsUserProfile } from "./_sections/awards";
import { CertificationsUserProfile } from "./_sections/certifications";
import { InterestsUserProfile } from "./_sections/interests";
import { ProjectsUserProfile } from "./_sections/projects";
import { PublicationsUserProfile } from "./_sections/publications";
import { useEffect, useState } from "react";

export const UserProfilePage = () => {

  return (
    <>
      <Helmet>
        <title>
          {`User Profile`} - {`Career Forge`}
        </title>
      </Helmet>

      <div className="max-w-2xl space-y-4">
        <motion.h1
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          className="text-4xl font-bold tracking-tight"
        >
          {`User Profile`}
        </motion.h1>

        <ScrollArea hideScrollbar className="h-[calc(100vh-140px)] lg:h-[calc(100vh-88px)]">
          <div className="space-y-6">
            <BasicsUserProfile />
          </div>
        </ScrollArea>
      </div>
    </>
  );
};

