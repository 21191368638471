import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "@lingui/macro";
import { Button } from "@reactive-resume/ui";
import { Combobox } from "@reactive-resume/ui";
import { Form, FormDescription, FormField, FormItem, FormLabel, Checkbox } from "@reactive-resume/ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useUpdateUser, useUser } from "@/client/services/user";
import { cn } from "@reactive-resume/utils";
import { useJobCategory } from "@/client/services/jobs-alert";

const formSchema = z.object({
  emailFrequency: z.enum(["weekly", "bi_weekly", "monthly"]).default("weekly"),
  jobCategories: z.array(z.string()),
});

type FormValues = z.infer<typeof formSchema>;

export const FormJobsAlert = () => {
  const { user } = useUser();
  const { updateUser, loading } = useUpdateUser();
  // const { jobCategories } = useJobCategory();
  const jobCategories = [
    { id: 1, category: "Healthcare" },
    { id: 2, category: "IT" },
    { id: 3, category: "Engineering" },
    { id: 4, category: "Finance" },
    { id: 5, category: "Education" },
    { id: 6, category: "Sales" },
    { id: 7, category: "Marketing" },
    { id: 8, category: "HR" },
    { id: 9, category: "Legal" },
    { id: 10, category: "Art" },
    { id: 11, category: "Design" },
    { id: 12, category: "Hospitality" },
    { id: 13, category: "Tourism" },
    { id: 14, category: "Retail" },
    { id: 15, category: "Customer Service" },
    { id: 16, category: "Transportation" },
    { id: 17, category: "Logistics" },
    { id: 18, category: "Science" },
    { id: 19, category: "Research" },
    { id: 20, category: "Agriculture" },
    { id: 21, category: "Environmental" },
    { id: 22, category: "Construction" },
    { id: 23, category: "Trades" },
    { id: 24, category: "Government" },
    { id: 25, category: "Public Service" },
    { id: 26, category: "Media" },
    { id: 27, category: "Communications" },
    { id: 28, category: "NonProfit" },
    { id: 29, category: "Social Services" }
  ];


  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: { emailFrequency: "weekly", jobCategories: [] },
  });

  useEffect(() => {
    user && onReset();
  }, [user]);

  const onReset = () => {
    if (!user) return;

    form.reset({ emailFrequency: user.emailFrequency ?? "weekly", jobCategories: user.job_categories ?? [] });
  };

  const onSubmit = async (data: FormValues) => {
    if (!user) return;

    const selectedJobCategories = data.jobCategories ?? [];

    await updateUser({ emailFrequency: data.emailFrequency, job_categories: selectedJobCategories });

    window.location.reload();

  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-2xl font-bold leading-relaxed tracking-tight">{`Create Job Preferences`}</h3>
        <p className="leading-relaxed opacity-75">
          {`Here, you can create job preferences and set email frequency according to your preference.`}
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-6 sm:grid-cols-2">
          <FormField
            name="emailFrequency"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{`Email Frequency`}</FormLabel>
                <div style={{ height: '5px' }} />
                <div className="w-full">
                  <Combobox
                    {...field}
                    value={field.value}
                    onValueChange={field.onChange}
                    options={[
                      { label: `Weekly`, value: "weekly" },
                      { label: `Bi-Weekly`, value: "bi_weekly" },
                      { label: `Monthly`, value: "monthly" },
                    ]}
                  />
                </div>
              </FormItem>
            )}
          />
        <div className={cn("hidden sm:col-start-1 sm:col-span-2 flex justify-start", form.formState.isDirty && "flex animate-in fade-in")}>
          <FormField
            name="jobCategories"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{`Job Categories`}</FormLabel>
                <div style={{ height: '5px' }} />
                <div className="w-full">
                  {jobCategories?.reduce((chunks: any[][], category: any, index: number) => {
                    if (index % 5 === 0) chunks.push([]);
                    const chunkIndex = Math.floor(index / 5);
                    chunks[chunkIndex].push(category);
                    return chunks;
                  }, []).map((chunk: any, chunkIndex: any) => (
                    <div key={chunkIndex}>
                      <div key={chunkIndex} className="flex">
                        {chunk.map((category: any) => (
                          <label key={category.id} className="inline-flex items-center mr-4">
                            <Checkbox
                              {...field}
                              key={category.id}
                              name="jobCategories"
                              value={category.category}
                              // defaultChecked={field.value.includes(category.category)}
                              checked={field.value.includes(category.category)}
                              onClick={(e) => {
                                const target = e.target as HTMLInputElement;
                                const isChecked = e.currentTarget.ariaChecked === 'true';
                                const value = target.value;
                                const currentCategories = field.value;
                                let updatedCategories;
                                if (!isChecked) {
                                  updatedCategories = [...currentCategories, value];
                                } else {
                                  updatedCategories = currentCategories.filter((item) => item !== value);
                                }
                                field.onChange(updatedCategories);
                              }}
                            />
                            <span className="ml-2">{category.category}</span>
                          </label>
                        ))}
                      </div>
                      {chunkIndex < Math.ceil(jobCategories.length / 5) - 1 && <div style={{ height: '20px' }} />}
                    </div>
                  ))}
                </div>
              </FormItem>
            )}
          />
        </div>


          <div
            className={cn(
              "hidden items-center space-x-2 self-center sm:col-start-2",
              form.formState.isDirty && "flex animate-in fade-in",
            )}
          >
          {/* <div className={cn("hidden sm:col-start-1 sm:col-span-2 flex justify-start", form.formState.isDirty && "flex animate-in fade-in")}> */}
            <Button type="submit" disabled={loading}>
              {t`Save Changes`}
            </Button>
            <Button type="reset" variant="ghost" onClick={onReset}>
              {t`Discard`}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
