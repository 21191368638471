import { queryClient } from "@/client/libs/query-client";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

export const sendingEmail = async (emailData: any) => {
  const response = await axios.post("/api/send-grid/send-email", emailData);

  return response.data;
};

export const downloadResume = async (userResume: any) => {
  const response = await axios.post("/api/send-grid/download-resume", {userResume}, {
    responseType: 'blob',
  });

  const blob = new Blob([response.data], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'resume.pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const downloadCoverLetter = async (content: string) => {
  const response = await axios.post("/api/send-grid/download-cover-letter", {content}, {
    responseType: 'blob',
  });

  const blob = new Blob([response.data], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'cover_letter.pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const useSendGrid = () => {
  const {
    error,
    isPending: loading,
    mutateAsync: sendingEmailFn,
  } = useMutation({
    mutationFn: sendingEmail,
    onSuccess: (data) => {
      queryClient.setQueryData(["message"], data);
    },
  });

  return { sendingEmail: sendingEmailFn, loading, error };
};
