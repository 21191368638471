import { queryClient } from "@/client/libs/query-client";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

export const insertUserJobs = async (data: any) => {
  const response = await axios.post("/api/jobs/insert-data-in-user-jobs", data);

  return response.data;
};

export const useInsertUserJobs = () => {
  const {
    error,
    isPending: loading,
    mutateAsync: insertUserJobsFn,
  } = useMutation({
    mutationFn: insertUserJobs,
    onSuccess: (data) => {
      queryClient.setQueryData(["message"], data);
    },
  });

  return { insertUserJobs: insertUserJobsFn, loading, error };
};
