import { createZodDto } from "nestjs-zod/dto";

import { jobsSchema } from "./job";

export const getJobsSchema = jobsSchema.partial().pick({
  category: true,
  id: true,
  job_title: true,
  company_name: true,
  url: true,
  end_date: true,
  email: true,
  job_desc: true,
});

export class GetJobsDto extends createZodDto(getJobsSchema) {}
