import { addUserVerification } from "@/client/services/user/update-user-credit";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "@reactive-resume/ui";
import { useState } from "react";
import { useForm } from "react-hook-form";

export const AddCreditDialog = ({ isOpen, close, userName, id }: any) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      await addUserVerification(id, parseInt(values.credits));
      setLoading(false);
      close();
      window.location.reload();
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const form = useForm({
    defaultValues: { credits: 0 },
  });

  return (
    <Dialog open={isOpen} onOpenChange={close}>
      <DialogContent>
        <Form {...form}>
          <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader>
              <DialogTitle>
                <div className="flex items-center space-x-2.5">
                  <h2>Add Credits for User: {userName}</h2>
                </div>
              </DialogTitle>
            </DialogHeader>

            <FormField
              name="credits"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{`Enter Credits`}</FormLabel>
                  <FormControl>
                    <div className="flex items-center justify-between gap-x-2">
                      <Input {...field} className="flex-1" type="number" placeholder="e.g 123" />

                    </div>
                  </FormControl>
                  <FormDescription>
                    {`Note: Credits will be added to existing balance on user account.`}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <div className="flex items-center">
                <Button
                  type="submit"
                  disabled={loading}
                  className={"rounded-r-none"}
                >
                  Add
                </Button>
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
