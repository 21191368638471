/* eslint-disable lingui/text-restrictions */

import axios from "axios";

const PROMPT = `You are a hiring manager for a {job_title} at a company called {company} in the {category} field. The role requires a candidate who perfectly matches the needs of the role and industry at large. Based on this, generate a set of interview questions in a numbered list that:
Assess the candidate's technical skills and knowledge.
Explore the candidate's previous experiences and achievements, focusing on their role, actions taken, and the outcomes.
Evaluate the candidate's problem-solving and critical thinking abilities with situational or hypothetical scenarios relevant to the job.
Determine the candidate's fit with the company culture and values through questions that reveal their personality, work ethic, and teamwork capabilities.
Include one or two creative or unconventional questions that can provide insights into the candidate's thinking process, creativity, or personality.
Ensure the questions are open-ended to encourage detailed responses and provide insights into the candidate's capabilities and fit for the role. Do not return anything else except the 10 questions. It should not begin with a newline. It should not have any prefix or suffix text.`;

export const interviewPreparation = async (job_title: string, company: string, category: string) => {
  const prompt = PROMPT.replace("{job_title}", job_title).replace("{company}", company).replace("{category}", category);

  const response = await axios.post(
    "/api/openai/send",
    {prompt},
  );

  return response.data ?? job_title;
};
