import { GetJobsDto } from "@reactive-resume/dto";
import { useQuery } from "@tanstack/react-query";
import { axios } from "@/client/libs/axios";
import { useJobDataStore } from "@/client/stores/job-data";
import { useEffect } from "react";

export const fetchJobsByIdentifier = async (email: string) => {
  const response = await axios.get<GetJobsDto[]>("/jobs/get-jobs-by-identifier", {
    params: { email },
  });
  return response.data;
};

export const useGetJobByIdentifier = (email: string) => {
  const setJobData = useJobDataStore((state) => state.setJobData);

  const { error, isLoading: loading, data } = useQuery<GetJobsDto[], Error>({
    queryKey: ["jobData"],
    queryFn: () => fetchJobsByIdentifier(email),
  });

  useEffect(() => {
    if (data) {
      setJobData(data);
    }
  }, [data, setJobData]);

  return { jobData: data, loading, error };
};
